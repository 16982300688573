// Libs
import React, { useMemo } from 'react';
import { defaults, get } from 'lodash';
// State
import { formatFieldsValue } from '../../libs/formatFromGraph';
// Deps
import HeroCta from './hero-cta';
import ProductCarousel from './product-carousel';
import SpecialMessage from './special-message';
import ComingSoonComponent from './coming-soon-component';
import HomepageTeasers from './homepage-teasers';
import Quote from '../common/quote';
import Grocery from '../common/grocery';
import ClassCta from './class-cta';
import RewardsFlyerGatewayContainer from './rewards-flyer-gateway-container';
// Assets
import styles from './homepage.module.scss';
import AppHidden from '../app/common/app-hidden';

const Homepage = ({ data }) => {
  const queryData = defaults(data || {}, {
    feature: {},
    feature2: {},
    feature3: {},
    hero: {},
    carousel: {},
    quote: {},
    groceryGateway: {},
    classesCTA: {},
    flyer: {},
    gateway: {},
    rewards: {},
    comingSoon: {},
    specialMessage: {}}
  );

  const pageData = formatFieldsValue(queryData);
  // console.log(pageData.hero);
  const featureList = [queryData.feature, queryData.feature2, queryData.feature3];
  const computeExpensiveValue = (featureList) => {
    featureList.map(item => {
      item.class = {};
      item.class.title = get(item, 'link.title', '');
      item.alias = get(item, 'link.uri', '');
      item.type = get(item, 'subtitle.value', '');
      item.class.image = item.image;
      return item;
    });
    return featureList;
  };
  const memoizedValue = useMemo(() => computeExpensiveValue(featureList), [featureList]);
  return (
    <div className={styles.homepage}>
      <HeroCta data={pageData.hero}/>
      <AppHidden>
        <SpecialMessage data={pageData.specialMessage}/>
        <ComingSoonComponent data={pageData.comingSoon}/>
      </AppHidden>
      <ProductCarousel data={pageData.carousel}/>
      <HomepageTeasers data={memoizedValue}/>
      <Quote data={pageData.quote}/>
      <Grocery className={styles.homepageFeaturedArea} grocery={pageData.groceryGateway}/>
      <ClassCta data={pageData.classesCTA}/>
      <RewardsFlyerGatewayContainer
        flyer={pageData.flyer}
        gateway={pageData.gateway}
        rewards={pageData.rewards}
      />
    </div>
  );
};

export default Homepage;
