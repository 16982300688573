// Libs
import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { has } from 'lodash';
// Deps
import SiteLink from '../common/site-link';
import Img from 'gatsby-image';
// Assets
import styles from './hero-cta.module.scss';

const HeroCta = ({ data }) => {
  // console.log('HeroCta',data.textcolor, has(data, 'textcolor'));
  const textColoClass = has(data, 'textcolor') ? (data.textcolor === 'black' ? styles.heroCtaDarkText : styles.heroCtaWhiteText) : styles.heroCtaWhiteText;
  const backgroundClass = has(data, 'textcolor') ? (data.textcolor === 'black' ? styles.heroCtaWhiteBackground : styles.heroCtaDarkBackground) : styles.heroCtaDarkBackground;
  return useMemo(
    () => (
      <div className={styles.heroCta}>
        {!!data.image && <Img className={styles.heroCtaImage} fluid={data.image.fluid} />}
        <div className={`${styles.heroCtaWrapper} ${backgroundClass}`}>
          <div className={styles.heroCtaContent}>
            {data.title && <h1 className={`${styles.heroCtaTitle} ${textColoClass}`}>{data.title}</h1>}
            {data.subTitle && <h3 className={`${styles.heroCtaSubTitle} ${textColoClass}`}>{data.subTitle}</h3>}
            {data.summary && <div className={`${styles.heroCtaSummary} ${textColoClass}`} dangerouslySetInnerHTML={{__html: data.summary}} />}
            {data.link && <SiteLink href={data.link.uri}>{data.link.title}</SiteLink>}
          </div>
        </div>
      </div>
    ),
    [data]
  );
};

HeroCta.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    summary: PropTypes.string,
    textcolor: PropTypes.string,
    link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }),
};

export default HeroCta;
