// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { has, isNull } from 'lodash';
// State
import CommonPrice from './price';
import { ClassDateDisplay } from '../../store/classFilters/action-types';
// Deps.
import BookingClassModal from '../booking/booking-class-modal';
import SiteLink from './site-link';
//Assets.
import styles from './class-card.module.scss';
import imgFallback from '../../images/class_hero_image.png';
import iconArrowSvg from '../../images/icons/icon-arrow.svg';
import locale from '../../locale/locale';
import { classAvailabilitySummaryLine, classDateFormat } from '../../libs/class/class-format';

const ClassCard = ({ classSchedule, availableStore, variant, dateAndTime, limitByDate = true }) => {
  const { availability = null, alias } = classSchedule;

  return (
    has(classSchedule, 'class.title') && (
      <div className={styles.classCard} data-variant={variant}>
        <SiteLink href={alias}>
          {classSchedule.class.image ? (
            <Img className={styles.classCardItemImage} fluid={classSchedule.class.image.fluid}/>
          ) : (
            <img className={styles.classCardItemImage} src={imgFallback} alt="Placeholder"/>
          )}
        </SiteLink>
        <div className={styles.classCardInfoContainer}>
          {variant === 'simple' && <h4 className={styles.classCardType}>{classSchedule.type}</h4>}
          <SiteLink href={alias} className={styles.classCardInlineLink}>
            <h4 className={styles.classCardTitle}>
              {classSchedule.class.title}
              <img className={styles.classCardItemImage} src={iconArrowSvg} alt="Placeholder"/>
            </h4>
          </SiteLink>
          {dateAndTime && dateAndTime !== ClassDateDisplay.HIDE && (
            <div className={styles.classCardDate}>
              {dateAndTime !== ClassDateDisplay.HIDE && classDateFormat(classSchedule)}
              {dateAndTime === ClassDateDisplay.DATE_AND_TIME && `, ${classSchedule.startTime}`}
            </div>
          )}
          {classSchedule.withWine && (
            <div className={styles.classCardWine}>
              {locale.classBooking.labels.winePairingAvailable}
            </div>
          )}
          {!isNull(availability) && <>
            <div className={styles.classCardFormatAndSpots}>
              <span>{classSchedule.classFormat}</span>&nbsp;|&nbsp;
              {classAvailabilitySummaryLine(classSchedule)}

            </div>
            <div className={styles.classCardBookAndPrice}>
              <BookingClassModal
                classSchedule={classSchedule}
                limitByDate={limitByDate} // Class cards are always singular dates, enforce the limit.
                availableStore={availableStore}
                buttonClass={styles.classCardBookClass}
              />
              <CommonPrice price={classSchedule.price}/>
            </div>
          </>}
        </div>
      </div>
    )
  );
};

ClassCard.defaultProps = {
  classSchedule: null,
  variant: null,
  dateAndTime: ClassDateDisplay.DATE,
  availableStore: null,
  limitByDate: true,
};

ClassCard.propTypes = {
  classSchedule: PropTypes.object.isRequired,
  variant: PropTypes.string,
  dateAndTime: PropTypes.string,
  availableStore: PropTypes.number,
  limitByDate: PropTypes.bool,
};

export default ClassCard;
