// Libs
import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';
// Assets
import styles from './homepage-teasers.module.scss';
import ClassCard from '../common/class-card';

const HomepageTeasers = ({ data }) => {
  // console.log('HomepageTeasers', data);

  return useMemo(
    () => (
      <div className={styles.homepageTeasers}>
        <div className={styles.homepageTeasersContainer}>
          {Array.isArray(data) &&
          data.length &&
          data.map((classSchedule) => (
            <ClassCard key={uniqueId(`class_${classSchedule.nid}`)} classSchedule={classSchedule} variant={'simple'} dateAndTime={null}/>
          ))}
        </div>
      </div>
    ),
    [data],
  );
};

export default HomepageTeasers;
