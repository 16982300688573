// Libs
import React  from 'react';
import { graphql } from 'gatsby';
// Deps
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import Homepage from '../components/homepage/homepage';

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" keywords={[]}/>
      <Homepage data={data}/>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    hero: configPagesHomePage {
      id
      label
      nid: drupal_id
      relationships {
        image: field_hero_image {
          localFile {
            id
            childImageSharp {
              fluid(maxHeight: 810, srcSetBreakpoints: [557, 810]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      link: field_hero_button {
        title
        uri
      }
      subTitle: field_hero_description {
        value
      }
      title: field_hero_title {
        value
      }
      textcolor: field_herotextcolor 
    }
    specialMessage: configPagesHomePage {
      message: field_specialmessage
      show: field_showspecialmessage
    }
    comingSoon: configPagesHomePage {
      message: field_coming_soon
      show: field_show_coming_soon
    }
    carousel: configPagesHomePage {
      title: field_carousel_title {
        value
      }
      relationships {
        products: field_product_reference {
          title
          price: field_product_price {
            value
          }
          savings: field_product_savings {
            value
          }
          summary: field_product_quality_descriptio {
            value
          }
          relationships {
            image: field_product_images {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 320) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    quote: configPagesHomePage {
      title: field_quote {
        value
      }
      author: field_quote_author {
        value
      }
    }
    groceryGateway: configPagesHomePage {
      label: field_grocery_gateway_label {
        value
      }
      title: field_grocery_gateway_title {
        value
      }
      text: field_grocery_gateway_text {
        value
      }
      link: field_grocery_gateway_link {
        uri
        title
      }
      relationships {
        image: field_grocery_gateway_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 570) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    classesCTA: configPagesHomePage {
      title: field_classes_cta_title {
        value
      }
      subTitle: field_classes_cta_text {
        value
      }
      link: field_classes_cta_link {
        uri
        title
      }
      textcolor: field_classesctatextcolor
      relationships {
        image: field_classes_cta_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 810, srcSetBreakpoints: [557, 810]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    rewards: configPagesHomePage {
      title: field_rewards_feature_title {
        value
      }
      text: field_rewards_feature_text {
        value
      }
      link: field_rewards_feature_link {
        uri
        title
      }
      relationships {
        image: field_rewards_feature_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 555) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    flyer: configPagesHomePage {
      title: field_flyer_feature_title {
        value
      }
      text: field_flyer_feature_text {
        value
      }
      link: field_flyer_feature_link {
        uri
        title
      }
      relationships {
        image: field_flyer_feature_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 555) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    gateway: configPagesHomePage {
      title: field_gateway_feature_title {
        value
      }
      text: field_gateway_feature_text {
        value
      }
      link: field_gateway_feature_link {
        uri
        title
      }
      relationships {
        image: field_gateway_feature_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 555) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    feature:configPagesHomePage {
      id
      relationships {
        image:field_feature {
          localFile {
            childImageSharp {
              fluid(maxHeight: 408) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      link:field_feature_link {
        uri
        title
      }
      subtitle:field_feature_subtitle {
        value
        format
        processed
      }
    }
    feature2:configPagesHomePage {
      id
      relationships {
        image:field_feature_image_2 {
          localFile {
            childImageSharp {
              fluid(maxHeight: 408) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      link:field_feature_link_2 {
        uri
        title
      }
      subtitle:field_feature_subtitle_2 {
        value
        format
        processed
      }
    }
    feature3:configPagesHomePage {
      id
      relationships {
        image:field_feature_image_3 {
          localFile {
            childImageSharp {
              fluid(maxHeight: 408) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      link:field_feature_link_3 {
        uri
        title
      }
      subtitle:field_feature_subtitle_3 {
        value
        format
        processed
      }
    }
  }
`;
