// Libs
import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';
// Assets
import styles from './special-message.module.scss';
import navigate from '../../libs/navigate';

const SpecialMessage = ({ data }) => {
  return (
    <>
      {data.show && (
        <div className={styles.specialMessageContainer} onClick={()=> navigate('/special-message')}>{data.message}</div>
      )}
    </>
  );
};

export default SpecialMessage;
