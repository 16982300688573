// Libs
import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
// Assets
import 'swiper/dist/css/swiper.min.css';
import styles from './product-carousel.module.scss';
import SiteLink from '../common/site-link';
import locale from '../../locale/locale';

import loadableVisibility from 'react-loadable-visibility/loadable-components';
import ButtonLoader from '../common/loaders/button-loader';

const ProductCarouselSlider = loadableVisibility(() => import('./product-carousel-slider'), {
  fallback: <ButtonLoader/>,
});

const ProductCarousel = ({ data }) => {
  return useMemo(
    () => (
      <div className={styles.productCarousel}>
        <div className={styles.productCarouselSection}>
          <div className={styles.productCarouselTitleContainer}>
            <h2 className={styles.productCarouselTitle}>
              {data.title}
            </h2>
            <div className={styles.productCarouselTitleAll}>
              <SiteLink href={'/fresh-this-week/'}>{locale.faqs.viewAll}</SiteLink>
            </div>
          </div>
          <div className={styles.productCarouselSlider}>
            <ProductCarouselSlider data={data}/>
          </div>
        </div>
        <div className={styles.productCarouselBottomLine}/>
      </div>
    ),
    [data],
  );
};

ProductCarousel.propTypes = {
  data: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.string,
        savings: PropTypes.string,
        title: PropTypes.string,
        summary: PropTypes.string,
        // image: PropTypes.string,
      }),
    ),
  }),
};

export default ProductCarousel;
