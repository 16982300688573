import React, { useMemo } from 'react';
import styles from './rewards-flyer-gateway-container.module.scss';
import Rewards from '../common/homepage_teasers/rewards';
import Flyer from '../common/homepage_teasers/flyer';
import Gateway from '../common/homepage_teasers/gateway';

const RewardsFlyerGatewayContainer = ({ rewards, flyer, gateway }) => {
  return useMemo(
    () => (
      <div className={styles.rewardsFlyerGatewayContainerWrapper}>
        <div className={styles.rewardsFlyerGatewayContainer}>
          <Rewards rewards={rewards} />
          <div className={styles.rewardsFlyerGatewayTwoColl}>
            <Flyer flyer={flyer} />
            <Gateway gateway={gateway} />
          </div>
        </div>
      </div>
    ),
    [rewards, flyer, gateway]
  );
};

export default RewardsFlyerGatewayContainer;
